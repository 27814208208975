<template>
    <div size="A4" class="page">
        <div v-if="loading">
            <Loading />
        </div>
        <!-- <b-overlay :show="loading"> -->
        <template v-else>
            <div class="text-right">
                <b-button @click="pdfExport" variant="primary" class="mb-3" size="sm">
                    <i class="ri-printer-fill"></i>
                    {{ $t('teaGardenConfig.Print') }}
                </b-button>
            </div>
            <div style="border: 1px double #222; padding: 1rem; height: 100%">
                <!-- <TeaGardenReportHead :baseUrl="teaGardenServiceBaseUrl" :uri="'/configuration/report-heading/detail'" :orgId="5" :loadingState="false">
                    {{ $t('teaGardenService.small_tea_farmer_certificate_title') + ' ' + $t('globalTrans.certificate') }}
                </TeaGardenReportHead> -->
                <div class="text-center" style="text-decoration: underline;">{{ $t('lrcpnServicePanel.acid_import_clearance_application_certificate') }}</div>
                <br>
                <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                    <div class="" style="width:50%"><b>{{ $t('globalTrans.application_id') }}</b> : {{ appDetail.application_id ? EngBangNum(appDetail.application_id) : '' }}</div>
                    <div class="text-right" style="width:50%"><b>{{ $t('globalTrans.date') }}</b> : {{ appDetail.approved_date | dateFormat }}</div>
                </div>
                <div class="mb-3">
                    <p>
                        {{ currentLocale === 'en'
                        ? 'Subject to compliance with the provisions of Rule 4(6) of the Acid (Import, Production, Storage, Transportation, Sale, and Use) Control Regulations, 2004, and the conditions specified in this permit, a license for the import of acid is hereby granted to the following individual/institution/organization based on their application dated ' + EngBangNum(formattedDate)
                        : 'এসিড (আমদানি, উৎপাদন, মজুদ, পরিবহন, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০০৪ এর বিধি ৪ (৬) এর বিধানাবলী এবং এই ছাড়পত্রে উল্লিখিত শর্তাবলী পালন সাপেক্ষে নিম্নবর্ণিত ব্যক্তি/প্রতিষ্ঠান/সংস্থার/তাঁর ' + EngBangNum(formattedDate) + ' তারিখের আবেদনের প্রেক্ষিতে এসিড আমদানির জন্য হাড়পত্র প্রদান করা হইল।' }}
                    </p>
                </div>
                <table class="report-info">
                    <tbody>
                        <tr>
                            <td style="width: 3%;">{{ $n(1) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_institution') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;"> {{ currentLocale === 'en' ? appDetail.applicant_name : appDetail.applicant_name_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(2) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_owner') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;"> {{ currentLocale === 'en' ? appDetail.applicant_name : appDetail.applicant_name_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(3) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('globalTrans.address') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;">{{ currentLocale === 'en' ? appDetail.business_info?.business_address : appDetail.business_info?.business_address_bn }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(4) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? ' (A) ' : ' (ক) ' }}{{ $t('lrcpnServicePanel.drug_no') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.trade_license_info.license_no">{{ appDetail.business_info?.trade_license_info?.license_no ? EngBangNum(appDetail.business_info?.trade_license_info.license_no) : '' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(B) ' : '(খ) ' }}{{ $t('lrcpnServicePanel.drug_license_authority') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.drug_license_info?.license_provider">{{ appDetail.business_info?.drug_license_info?.license_provider ? (appDetail.business_info?.drug_license_info?.license_provider) : ''}}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(C) ' : '(গ) ' }}{{ $t('lrcpnServicePanel.issue_date') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.drug_license_info?.license_issue_date">{{ appDetail.business_info?.drug_license_info?.license_issue_date | dateFormat }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(D) ' : '(ঘ) ' }}{{ $t('lrcpnServicePanel.duration_drug_license') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.drug_license_info?.license_expire_date">{{ appDetail.business_info?.drug_license_info?.license_expire_date | dateFormat }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(5) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 40%; vertical-align: top;">{{ $t('lrcpnServicePanel.acid_details') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td  style="width: 50%;" v-if="appDetail.business_info?.acid_import_worth_value_infos">
                                <b-table-simple style="width: 100%" bordered>
                                                <b-tr>
                                                    <b-th class="text-center" style="width: 50%;"> {{ $t('lrcpnServicePanel.acid_name') }}</b-th>
                                                    <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.qty') }}</b-th>
                                                    <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.price') }}</b-th>
                                                </b-tr>
                                                <template v-if="appDetail.business_info?.acid_import_worth_value_infos?.length">
                                                  <slot v-for="(item, index) in appDetail.business_info?.acid_import_worth_value_infos">
                                                        <b-tr :key="index">
                                                            <b-td class="text-center">
                                                                {{ getAcidName(item.acid_id) }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ $n(item.qty) }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ $n(item.price) }}
                                                            </b-td>
                                                        </b-tr>
                                                    </slot>
                                                </template>
                                                <template v-else>
                                                    <b-tr>
                                                        <b-td colspan="3" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                                    </b-tr>
                                                </template>
                                            </b-table-simple>
                                        </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(6) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.purpose_acid') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.present_import_info?.purpose"> {{ appDetail.business_info?.present_import_info?.purpose }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(7) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.acid_value') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.acid_import_worth_value_infos"> {{ $n(totalAmount) }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(8) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_address') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.import_agent_name"> {{ (appDetail.business_info?.import_agent_name) }}, {{ (appDetail.business_info?.import_agent_address) }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(9) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.port_entry') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.present_import_info?.access"> {{ (appDetail.business_info?.present_import_info?.access) }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(10) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.expiration_license') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;">  </td>
                        </tr>
                    </tbody>
                </table>
                <strong style="margin-top:2rem; display: block;">{{ $t('teaGardenPanel.conditions') }} :</strong>
                <!-- <div class="text-justify" v-html="currentLocale === 'en' ? application.application.service_instruction.instruction_en : application.application.service_instruction.instruction_bn"></div> -->
                <table style="width:100%; margin-top: 2rem;">
                    <tbody>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(1) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'This permit shall not be transferable.' : 'এই ছাড়পত্র হস্তান্তরযোগ্য হইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(2) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'This permit may not be used more than once.' : 'এই ছাড়পত্র একবারের বেশী ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(3) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'Acid or its components or materials imported under this permit shall not be used for any purpose other than the purpose specified in this permit.' : 'এই ছাড়পত্রের মাধ্যমে আমদানীকৃত এসিড বা উহার উপাদান বা উপকরণ এই ছাড়পত্রে উল্লিখিত উদ্দেশ্য ব্যতীত অন্য কোন উদ্দেশ্যে ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(4) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'All financial and legal responsibilities related to the import under this permit shall rest with the importer.' : 'এই ছাড়পত্রের মাধ্যমে আমদানীর ক্ষেত্রে যাবতীয় আর্থিক ও আইনগত দায়-দায়িত্ব আমদানীকারকের থাকিবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(5) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'Routes and entry ports other than those specified in this permit shall not be used.' : 'এই ছাড়পত্রের বর্ণিত রুট এবং প্রবেশ বন্দর ব্যতীত অন্য কোন রুট বা বন্দর ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(6) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'If this permit is not used within the specified time, it will be considered void. The issuing authority may renew it upon showing a valid reason. However, no permit shall be renewed more than once.' : 'নির্ধারিত সময়ের মধ্যে এই ছাড়পত্র ব্যবহার করা না হইলে উহা বাতিল বলিয়া গণ্য হইবে। উপযুক্ত কারণ দর্শাইলে প্রদানকারী কর্তৃপক্ষ উহা নবায়ন করিতে পারিবে। তবে একবারের বেশী কোন ছাড়পত্র নবায়ন করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(7) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? "To import any acid under this permit, the Acid Control Act, 2002 (Act No. 1 of 2002), and the Acid (Import, Production, Storage, Transportation, Sale, and Use) Control Regulations, 2004, as well as the government's foreign exchange policies, import policies, and customs, excise, and VAT-related policies and regulations, must be properly followed." : 'এই ছাড়পত্রের মাধ্যমে কোন এসিড আমদানী করিতে হইলে এসিড নিয়ন্ত্রণ আইন, ২০০২ (২০০২ সনের ১নং আইন) এবং এসিড (আমদানি, উৎপাদন, মজুদ, পরিবহণ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০০৪ ছাড়াও সরকারের বৈদেশিক মুদ্রাসংক্রান্ত নীতিমালা, আমদানী নীতিমালা এবং শুল্ক, আবগারী ও ভ্যাটসংক্রান্ত নীতি ও বিধিমালা যথাযথভাবে অনুসরণ করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(8) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'For any reasonable cause, the issuing authority may cancel, withdraw, or temporarily suspend this permit without providing any reason.' : 'যে-কোন যুক্তিসংগত কারণে এই ছাড়পত্র কোনরূপ কারণ দর্শানো ব্যতিরেকে উহার প্রদানকারী কর্তৃপক্ষ বাতিল, প্রত্যাহার বা সাময়িকভাবে স্থগিত রাখিতে পারিবে।' }}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- aproved info -->
                <table style="margin-top: 3rem;">
                    <tr>
                        <td style="width: 45%; text-align:justify; vertical-align: top;">
                            {{ $t('globalTrans.date') }} : <span style="text-decoration-style: dotted; text-decoration-line: underline;">
                                {{ appDetail.approved_date | dateFormat }}
                            </span>
                        </td>
                        <td style="width: 20%; text-align: center; padding-right: 10px; padding-left: 10px; vertical-align: top;">
                        </td>
                        <td style="width: 35%; margin-left: 10px; padding-left: 10px; vertical-align: top;">
                            <div>
                                {{ $t('lrcpnServicePanel.seal_authority') }} <br><br>
                                <p v-if="appDetail.approver_name">{{ $t('globalTrans.name') }} :
                                    <span style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? appDetail.approver_name : appDetail.approver_name_bn }}</span>
                                </p>
                                <p v-if="appDetail.designation_id">{{ $t('globalTrans.designation') }} :
                                   {{ getDesignationName(appDetail.designation_id) }}
                                </p>
                                <p v-if="appDetail.office_id">{{ $t('globalTrans.office') }} :
                                   {{ getOfficeName(appDetail.office_id) }}
                                </p>
                                <!-- <p>{{ $t('globalTrans.date') }} :<span
                                    style="text-decoration-style: dotted; text-decoration-line: underline;">{{ appDetail.approved_date | dateFormat }}</span>
                                </p> -->
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="text-left mt-3">
                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
            </div>
        </template>
        <!-- </b-overlay> -->
    </div>
</template>
  <script>
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  import { acidImportClearaneCertificateApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import Loading from './loading/Details.vue'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Loading
    },
    data () {
      return {
        lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          company_name: '',
          limit: 10
        },
        items: [],
        profile: this.$store.state.ExternalUserService.eBizPanel.associationProfile,
        associationInfoList: [],
        warehouseList: [],
        viewitemId: 0
      }
    },
    created () {
      this.loadData()
    },
    watch: {
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
          return this.$i18n.locale
        },
        formattedDate () {
          return this.appDetail.created_at.slice(0, 10)
        },
        typeOfBusinessList () {
          return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
        },
        appId () {
          return this.$route.params.id
        },
        masterAcidNameList () {
          if (this.$store.state.Auth.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
          } else {
            return this.$store.state.LrcpnService.commonObj.masterAcidNameList.filter(item => item.status === 1)
          }
        },
        totalAmount () {
            let total = 0
            this.appDetail.business_info.acid_import_worth_value_infos.forEach(item => {
                total += item.price
            })
            return total
        },
        divisionList () {
            return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        districtList () {
            return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
        },
        cityCorporationList () {
            return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1)
        },
        upazilaList () {
            return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1)
        },
        municipalityList () {
            return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1)
        },
        unionList () {
            return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1)
        },
        officeList () {
            return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
        },
        designationList () {
            return this.$store.state.CommonService.commonObj.designationList.filter(item => item.status === 1)
        },
        areaTypeList () {
            return this.$store.state.commonObj.areaTypeList
        }
    },
    methods: {
        getTypeOfBussiness (businessId) {
                const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1).find(item => item.value === businessId)
                if (cateObj !== undefined) {
                    if (this.$i18n.locale === 'bn') {
                        return cateObj.text_bn
                    } else {
                        return cateObj.text_en
                    }
                }
        },
        EngBangNum (n) {
                if (this.$i18n.locale === 'bn') {
                    return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
                } else {
                    return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
                }
        },
        searchData () {
            this.loadData()
        },
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(lrcpnServiceBaseUrl, acidImportClearaneCertificateApi + '/' + this.appId)
            if (result.success) {
            this.appDetail = result.data
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        },
        async pdfExport () {
            this.loading = true
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 1, app_id: this.appId })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getPdfData(lrcpnServiceBaseUrl, acidImportClearaneCertificateApi + '/' + this.appId, params)
            var blob = new Blob([result], {
            type: 'application/pdf'
            })
            this.loading = false
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        getAcidName (id) {
            const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getOfficeName (id) {
            const obj = this.officeList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getDesignationName (id) {
            const obj = this.designationList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getAreaTypeList (id) {
            const obj = this.areaTypeList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getDivisionName (id) {
            const obj = this.divisionList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getDistrictList (id) {
            const obj = this.districtList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getCityCorporationList (id) {
            const obj = this.cityCorporationList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getUpazilaList (id) {
            const obj = this.upazilaList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getMunicipalityList (id) {
            const obj = this.municipalityList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getUnionList (id) {
            const obj = this.unionList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        back () {
            this.$router.go(-1)
        }
    }
  }
  </script>
<style scoped>
.page {
    background: white;
    padding: .5in;
    margin: 0 auto;
    margin-bottom: 0.5cm;
}

.page[size="A4"] {
    width: 21cm;
    min-height: 29.7cm;
    height: auto;
}

.report-table {
    margin-top: 1rem;
    width: 100%;
}

.report-table td,
.report-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
}

@media print {

    body,
    .page {
        margin: 0;
        box-shadow: 0;
    }
}
</style>
